import WOW from 'wowjs';
import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { HashLink } from 'react-router-hash-link';


import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const Home = () => {

    const [activeSection, setActiveSection] = useState('');
    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);

    const setVisible = (active) => {
        if (activeSection === active) setActiveSection('');
        else setActiveSection(active);
    };

    return (
        <div className='thanos-page'>
            <div>
                <Navbar activeSection={activeSection} />
                {/* Banner */}
                <VisibilitySensor onChange={() => setVisible('Banner')}>
                    <section className="banner-section" id="home">
                        <div className='banner-bg'></div>
                        <div className="auto-container">
                            <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className='banner-left-area'>
                                    <div className="banner-text wow slideInLeft" data-wow-delay='100ms'>
                                        <h1>thanos</h1>
                                    </div>
                                    <div className="btn-area">
                                        <ul>                                    
                                            <li><HashLink to="#joinme" smooth className="btn-style-one"><span>join us</span></HashLink> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="banner-image wow slideInRight" data-wow-delay='100ms'>
                                    <img src={require("../../static/images/banner-img.png")} alt="" />
                                </div>
                            </div>
                            
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Whats Section */}
                <VisibilitySensor onChange={() => setVisible('About')}>
                    <section className='about-sec' id="about-sec">
                        <div className='banner-bg'></div>
                        <div className='auto-container'>
                            <div className='row'>
                                
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="heading-area-right wow zoomIn">
                                        <div className='ring-img'><img src={require("../../static/images/ring-img.png")} alt="" /></div>
                                        <div className="heading-box">
                                            <img src={require("../../static/images/lazy-2-img.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="heading-area-left">
                                        <div className="heading-box wow zoomIn">
                                            <h3>I am Memetoken</h3>
                                            <h4>But you can call me </h4>
                                            <h2>THANOS</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Whats Section */}
                <VisibilitySensor onChange={() => setVisible('Invest')}>
                    <section className='invest-sec' id="invest-sec">
                        <div className='banner-bg'></div>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='sec-title wow zoomIn'>
                                        <h2>Invest in <span><i>&#36;</i>THANOS</span> <br /> to bring balance to <br />  crypto world</h2>
                                    </div>
                                    <div className='invest-weight-area'>
                                        <div className='left-area-weight wow fadeIn'>
                                            <div className='coins-img'>
                                                <img src={require("../../static/images/left-invest.png")} alt="" />
                                            </div>
                                            <img src={require("../../static/images/left-weight.png")} alt="" />
                                        </div>
                                        <div className='center-area-weight'>
                                            <img src={require("../../static/images/center-weight.png")} alt="" />
                                        </div>
                                        <div className='right-area-weight wow fadeIn'>
                                            <div className='coins-img'>
                                                <img src={require("../../static/images/right-invest.png")} alt="" />
                                            </div>
                                            <img src={require("../../static/images/right-weight.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Form Section */}
                {/* <VisibilitySensor onChange={() => setVisible('Form')}>
                    <section className='form-sec' id="form-sec">
                        <div className='auto-container'>
                            <div className='row'>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="heading-area-left wow slideInLeft">
                                        <div className='title-box'>
                                            <h3>Own A piece of <br /> history  </h3>
                                        </div>
                                        <div className="heading-box">
                                            <img src={require('../../static/images/buy-img.png')} alt='' />
                                            <h3>BUYY!</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="heading-area-right wow slideInRight">
                                        <div className="heading-box">
                                            <div className='form-box'>
                                                <iframe src="https://raydium.io/swap/?inputMint=sol&outputMint=2GCKQBYot4uFyauVJvcZhiQP8gXgiNQJuYVAo6SVeUti" height="200" width="300" title="Iframe Example"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </VisibilitySensor> */}

                {/* Join Section */}
                <VisibilitySensor onChange={() => setVisible('Join')}>
                    <section className='join-sec' id="joinme">
                        <div className='banner-bg'></div>
                        <div className='auto-container'>
                            <div className='row'>
                                <div className="col-lg-7 col-md-12">
                                    <div className="heading-area-left wow fadeIn">
                                        <div className='sec-title wow zoomIn'>
                                            <h3>Join me on</h3>
                                            <h2>Titan</h2>
                                        </div>
                                        <ul>
                                            <li><a href="https://t.me/ThanosMemeToken" target='_blank'><img src={require('../../static/images/menu-telegram.png')} alt='' /></a></li>
                                            <li><a href="https://x.com/ThanosMemeToken" target='_blank'><img src={require('../../static/images/menu-x.png')} alt='' /></a></li>
                                        </ul>   
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="heading-area-right wow fadeIn">
                                        <div className="heading-box">
                                            <div className='light-img'>
                                                <img src={require("../../static/images/light-footer.png")} alt="" />
                                            </div>
                                            <img src={require("../../static/images/join-me-img.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>
            </div>
        </div >
    );
}

export default Home;